<template>
    <div class="page">
        <div class="search-box section">
            <el-form ref="query" :inline="true" :model="query" class="demo-form-inline" size="medium">
                <el-form-item prop="userName">
                    <el-input v-model="query.userName" clearable placeholder="请输入车场方" prefix-icon=""
                        class="margin-right10" @blur="doSearch" @clear="doSearch"></el-input>
                </el-form-item>
                <!-- <el-form-item prop="taskType">
                    <el-select v-model="query.taskType" clearable placeholder="请选择任务类型"
                        class="width260 margin-right10 margin-left38">
                        <el-option label="清分" value="0"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item prop="status">
                    <el-select v-model="query.status" @change="doSearch" clearable placeholder="请选择状态"
                        class="margin-right10">
                        <el-option label="待审核" value="0"></el-option>
                        <el-option label="审核通过" value="1"></el-option>
                        <el-option label="驳回" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item prop="status">
                    <el-select v-model="query.status" @change="doSearch" clearable placeholder="请选择审核人"
                        class="margin-right10">
                        <el-option label="已审核" value="0"></el-option>
                        <el-option label="未禁用" value="1"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item prop="phone">
                    <el-input v-model="query.phone" clearable placeholder="请输入手机号" prefix-icon="" class="margin-right10"
                        @blur="doSearch" @clear="doSearch"></el-input>
                </el-form-item>
                <el-form-item prop="searchDate">
                    <div class="xxd-input__wrap" style="border: none;">
                        <el-select v-model="query.type" class="width120" placeholder="时间范围">
                            <el-option label="申请时间" value="0"></el-option>
                            <el-option label="审核时间" value="1"></el-option>
                        </el-select>
                        <el-date-picker v-model="searchDate" end-placeholder="结束日期" range-separator="-"
                            start-placeholder="开始日期" @change="doSearch" type="daterange" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSearch">搜索</el-button>
                    <el-button @click="resetQueryForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="search-box page-header height50 padding20-32">
            <div class="content" style="margin-left: 0;">
                <div class="content-item">
                    <div class="title">信息条数</div>
                    <div class="text">{{ dataForm.total || 0 }}</div>
                </div>
            </div>
            <div class="content">
                <div class="content-item">
                    <div class="title">累计提现金额（元）</div>
                    <div class="text">{{ dataForm.applyAmount || 0 }}</div>
                </div>
            </div>
            <!-- <div class="content">
                <div class="content-item">
                    <div class="title">账户剩余金额（元）</div>
                    <div class="text">{{ dataForm.realAmount || 0 }}</div>
                </div>
            </div> -->
            <div class="content">
                <div class="content-item">
                    <div class="title">累计到账金额（元）</div>
                    <div class="text">{{ dataForm.realAmount || 0 }}</div>
                </div>
            </div>
            <div class="content">
                <div class="content-item">
                    <div class="title">商户手续费（元）</div>
                    <div class="text">{{ dataForm.feeSum || 0 }}</div>
                </div>
            </div>
            <div class="content">
                <div class="content-item">
                    <div class="title">通道手续费（元）</div>
                    <div class="text">{{ dataForm.channelFeeSum || 0 }}</div>
                </div>
            </div>
        </div>

        <div class="content-box section">
            <!--信息统计框-->
            <div>
                <el-table v-loading="isLoading" :data="dataList" border class="common-table" size="medium"
                    style="width: 100%">
                    <el-table-column label="车场方" prop="userName" width="160">
                    </el-table-column>
                    <!-- <el-table-column label="任务类型" prop="taskType" width="160">
                        <template slot-scope="scope">
                            {{ scope.row.taskType == 0 ? "清分" : "" }}
                        </template>
</el-table-column> -->
                    <el-table-column label="手机号" prop="phone" width="120">
                    </el-table-column>
                    <el-table-column label="提现金额" prop="applyAmount" width="120">
                    </el-table-column>
                    <el-table-column label="商户手续费" prop="fee" width="120">
                    </el-table-column>
                    <el-table-column label="通道手续费" prop="channelFee" width="120">
                    </el-table-column>
                    <el-table-column label="充值金额" prop="rechartAmount" width="120">
                    </el-table-column>
                    <el-table-column label="到账金额" prop="realAmount" width="120">
                    </el-table-column>
                    <el-table-column label="申请时间" prop="applyTime" min-width="160">
                    </el-table-column>
                    <el-table-column label="审核时间" prop="auditTime" min-width="160">
                    </el-table-column>
                    <el-table-column label="审核状态" prop="status" width="120">
                        <template slot-scope="scope">
                            <span>{{
                                scope.row.status == 1
                                    ? "审核通过"
                                    : (scope.row.status == 2 ? "驳回" : "待审核")
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核人" prop="auditUserName" min-width="120">
                    </el-table-column>
                    <el-table-column label="开户人" prop="id" min-width="140">
                    </el-table-column>
                    <el-table-column label="开户行" prop="bankName" min-width="160" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column label="银行卡号" prop="bankCard" min-width="160">
                    </el-table-column>
                    <el-table-column label="提现流水号" prop="outTradeNo" min-width="180" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" prop="name" width="150px">
                        <template slot-scope="scope">
                            <el-button style="color: #FF720C !important;" type="text"
                                @click="updateParkingLotStatus(scope.row, '1')" v-if="scope.row.status == 0">通过
                            </el-button>
                            <el-button type="text" @click="updateParkingLotStatus(scope.row, '2')"
                                v-if="scope.row.status == 0">驳回
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination-box">
                <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize"
                    :total="pagination.totalSize" background layout="sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
        <!-- 审核弹窗 -->
        <el-dialog :append-to-body="true" :before-close="beforeAddDialogClose" :visible.sync="withConfigDialogVisible"
            class="common-dialog" :title="title" width="350px" :close-on-click-modal="false">
            <el-form ref="form" v-loading="isLoading" :inline="true" :model="form" label-width="100px"
                label-position="left">
                <!-- <p class="with_title">此操作将{{ status == 1 ? "通过" : "驳回" }}{{ form.userName }} 提现申请,提现金额￥{{ form.applyAmount
                    }},实际到账￥{{ form.realAmount }},通道手续费￥{{ form.channelFee }}</p> -->
                <!-- <el-row>
                    <el-col :span="24" v-if="status == 2">
                        <el-form-item label="驳回备注" prop="remark">
                            <el-input v-model="form.remark" type="textarea" style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <div class="flx with_row">
                    <p class="with_user">提现方：</p>
                    <p class="width_main with-line">{{ form.userName }}</p>
                </div>
                <div class="flx with_row">
                    <p class="with_user">提现金额：</p>
                    <p class="width_main with-line">￥{{ form.applyAmount }}</p>
                </div>
                <div class="flx with_row">
                    <p class="with_user">充值金额：</p>
                    <p class="width_main with-line" style="color:red">￥{{ form.rechartAmount }}</p>
                </div>
                <div class="flx with_row">
                    <p class="with_user">实际到账：</p>
                    <p class="width_main with-line">￥{{ form.realAmount }}</p>
                </div>
                <div class="flx with_row">
                    <p class="with_user">手续费：</p>
                    <p class="width_main with-line">￥{{ form.channelFee }}</p>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="success" @click="handleWithDraw" :disabled="count != 0" v-if="status == 1">通 过<span
                        v-if="count != 0">{{ count }}s</span></el-button>
                <el-button type="danger" @click="handleWithDraw" :disabled="count != 0" v-if="status == 2">驳 回<span
                        v-if="count != 0">{{ count }}s</span></el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { withdrawMoneyApplyPage, withdrawMoneyApprove } from "@/api/settlementManage";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";

export default {
    name: "sorting-summary",
    mixins: [BaseMixin, Permission],
    data() {
        return {
            defaultRegion: [],
            searchDate: [],
            dataList: [],
            totalMoney: 0,
            exportDialogFormVisible: false,
            form: {
                taskName: "",
                taskType: "",
                executionTime: "",
                parkingLotIds: [],
                settleName: "",
            },
            query: {
                sourcePort: "",
                status: "",
                rechargeObject: "",
                manualOperation: "",
                taskName: "",
                startDate: "",
                endDate: "",
                type: "0",
                phone: "",
                userName: ""
            },
            paymentMethod: {
                "-1": "",
                0: "支付宝",
                1: "微信",
                2: "农行",
            },
            withConfigDialogVisible: false,
            rules: {
                taskName: [{ required: true, message: "任务名称不能为空" }],
                parkingLotIds: [{ required: true, message: "车场不能为空" }],
                taskType: [{ required: true, message: "任务类型不能为空" }],
                executionTime: [{ required: true, message: "清分时间不能为空" }],
                settleName: [{ required: true, message: "结算方名称不能为空" }],
            },
            dataForm: {},
            status: "",
            count: 5,
            timeInter: null,
            title: "提现审核"
        };
    },
    components: {

    },
    methods: {
        //重置查询条件
        resetQueryForm() {
            this.query.type = "0";
            this.$refs.query.resetFields();
            this.searchDate = [];
            this.doSearch();
        },
        //查询
        doSearch() {
            this.pagination.pageNo = 1;
            this.pagination.pageSize = 10;
            this.search();
        },
        goDetail(id) {
            console.log(id);
        },
        beforeAddDialogClose(done) {
            this.$refs.form.resetFields();
            done();
        },
        cancel() {
            this.withConfigDialogVisible = false;
            this.$refs.form.resetFields();
        },
        updateParkingLotStatus(item, status) {
            // this.title = status == '1' ? "通过提现审核" : "驳回提现审核";
            this.form = JSON.parse(JSON.stringify(item));
            this.status = status;
            if (this.timeInter) {
                clearInterval(this.timeInter);
            }
            this.count = 5;
            this.timeInter = setInterval(() => {
                this.count--;
                if (this.count == 0) {
                    clearInterval(this.timeInter);
                }
            }, 1000)
            // timeInter();
            this.withConfigDialogVisible = true;
            // if (status == '1') {
            //     this.nowStatus = "通过";
            // } else if (status == '2') {
            //     this.nowStatus = "驳回";
            // }
            // this.$confirm(`此操作将${this.nowStatus}申请, 是否继续?`, "提示", {
            //     confirmButtonText: "确定",
            //     cancelButtonText: "取消",
            //     type: "warning",
            // }).then(() => {
            //     withdrawMoneyApprove({
            //         id: id,
            //         status: status,
            //     }).then((res) => {
            //         this.isLoading = false;
            //         if (res && res.code === 30 && res.result) {
            //             this.$message.success("操作成功！");
            //             this.doSearch();
            //         }
            //     });
            // })
            //     .catch(() => {

            //     });
        },
        handleWithDraw() {
            // if (this.status == '1') {
            //     this.nowStatus = "通过";
            // } else if (status == '2') {
            //     this.nowStatus = "驳回";
            // }
            // this.$confirm(`此操作将${this.nowStatus}申请, 是否继续?`, "提示", {
            //     confirmButtonText: "确定",
            //     cancelButtonText: "取消",
            //     type: "warning",
            // }).then(() => {
            withdrawMoneyApprove({
                id: this.form.id,
                status: this.status,
            }).then((res) => {
                this.isLoading = false;
                if (res && res.code === 30 && res.result) {
                    this.$message.success("操作成功！");
                    this.doSearch();
                    this.cancel();
                }
            });
            // })
            //     .catch(() => {

            //     });
        },
        async search() {
            this.isLoading = true;
            const params = this.paramFormat(this.query);
            if (this.searchDate && this.searchDate.length > 0) {
                params["startDate"] = this.searchDate[0];
                params["endDate"] = this.searchDate[1];
            }
            const res = await withdrawMoneyApplyPage(params);
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                this.dataList = returnObject.records;
                this.pagination.totalSize = returnObject.total;
                this.pagination.totalPages = returnObject.pages;
                this.dataForm = returnObject;

            }
        },
    },
};
</script>

<style lang="less" scoped>
.with_title {
    font-size: 25px;
    margin-bottom: 20px;
    line-height: 1.5;
}
.flx{
    display: flex;
    align-items: center;
    justify-content: center;
}
.with-line {
    // border-bottom: 1px solid black;
}
.with_user{
    // width: 150px;
    // margin-left: 250px;

}
.with_main{
    // width: 300px;
}
.with_row{
    font-size: 16px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
}
</style>