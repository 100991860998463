import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//条件查询结算方列表
export function findSettlementInfoByPage(params) {
    return request.get('/settlement/findSettlementInfoByPage?' + objectToQueryString(params))
}

export function settleListPage(params) {
    return request.post('/settlement/settleListPage?' + objectToQueryString(params))
}

//根据结算方id查询停车场列表
export function findParkingBySettlementId(id, pageSize, pageNo) {
    return request.get('/settlement/findParkingBySettlementId?id=' + id + '&pageSize=' + pageSize + '&pageNo=' + pageNo)
}

//根据结算方id获取结算方联系人列表
export function findContactInformationBySettlementId(id) {
    return request.get('/settlement/findContactInformationBySettlementId?id=' + id)
}

//根据结算方id获取企业认证信息
export function findEnterpriseCertification(id) {
    return request.get('/settlement/findEnterpriseCertification?id=' + id)
}

//根据结算方id查询结算方营业信息
export function findBusinessInformation(id) {
    return request.get('/settlement/findBusinessInformation?id=' + id)
}

//根据id获取结算方基本信息
export function findBaseSettlementInfoById(id) {
    return request.get('/settlement/findBaseSettlementInfoById?id=' + id)
}

//删除结算方关联停车场
export function deleteSettlementParking(body) {
    return request.post('/settlement/deleteSettlementParking', body)
}

//删除结算方
export function deleteSettlementById(id) {
    return request.get('/settlement/deleteSettlementById?id=' + id)
}

//保存企业认证信息
export function createOrUpdateEnterpriseCertification(body) {
    return request.post('/settlement/createOrUpdateEnterpriseCertification', body)
}

//创建或更新营业信息
export function createOrUpdateBusinessInformation(body) {
    return request.post('/settlement/createOrUpdateBusinessInformation', body)
}

//保存或更新结算方基本信息
export function createOrUpdateBaseSettlementInfo(body) {
    return request.post('/settlement/createOrUpdateBaseSettlementInfo', body)
}

//保存联系人信息
export function createContactInformation(body) {
    return request.post('/settlement/createContactInformation', body)
}

//结算方添加停车场
export function addSettlementParking(body) {
    return request.post('/settlement/addSettlementParking', body)
}

// 提现申请
export function doCashOutApply(params) {
    return request.get('/wallet/withdrawApply?' + objectToQueryString(params));
}



// 新增车场方
export function saveParkingManager(params) {
    return request.post('/parkingLotManager/saveParkingManager?' + objectToQueryString(params));
}


// 列表车场方
export function settlementPage(params) {
    return request.post('/settlement/settlementPage?' + objectToQueryString(params));
}

// 新增车场方
export function addSettlement(params) {
    return request.post('/settlement/addSettlement?' + objectToQueryString(params));
}

// 编辑车场方
export function editSettlement(params) {
    return request.post('/settlement/editSettlement?' + objectToQueryString(params));
}

// 详情车场方
export function settlementInfo(params) {
    return request.post('/settlement/settlementInfo?' + objectToQueryString(params));
}

// 删除车场方
export function delSettlement(params) {
    return request.post('/settlement/delSettlement?' + objectToQueryString(params));
}

// 详情提现规则
export function withdrawMoneyRuleInfo(params) {
    return request.post('/withdrawMoney/withdrawMoneyRuleInfo?' + objectToQueryString(params));
}

// 修改提现规则
export function withdrawMoneyEdit(params) {
    return request.post('/withdrawMoney/withdrawMoneyEdit' , params);
}

// 提现申请列表
export function withdrawMoneyApplyPage(params) {
    return request.post('/withdrawMoney/withdrawMoneyApplyPage?' + objectToQueryString(params));
}

// 提现审核
export function withdrawMoneyApprove(params) {
    return request.post('/withdrawMoney/withdrawMoneyApprove?' + objectToQueryString(params));
}

// 财务配置
export function financialConf(params) {
    return request.post('/withdrawMoney/financialConf?' + objectToQueryString(params));
}

// 财务配置详情
export function financialConfInfo(params) {
    return request.post('/withdrawMoney/financialConfInfo?' + objectToQueryString(params));
}

// 通道列表
export function channelsList(params) {
    return request.post('/settlement/channelsList?' + objectToQueryString(params));
}

// 结算方通道详情
export function settlementChannelInfo(params) {
    return request.post('/settlement/settlementChannelInfo?' + objectToQueryString(params));
}

// 切换结算方通道
export function chengeSettlementChannel(params) {
    return request.post('/settlement/chengeSettlementChannel?' + objectToQueryString(params));
}
